import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "A responsive chart of smartphone market share 📲",
  "description": "Smartphones, magnificent little things. But there's only 4 kinds. Draw a responsive stackchart of their marketshare.",
  "date": "2018-12-15T08:00:00.000Z",
  "published": "2018-12-15T08:00:00.000Z",
  "image": "./smartphones.png"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <lite-youtube {...{
      "videoid": "lbHy8SF39k8",
      "videostartat": "0"
    }}></lite-youtube>
    <p>{`Smartphones, magnificent little things. But there's only 4 kinds. Draw a responsive stackchart of their marketshare.`}</p>
    <strong>Dataset: </strong>
    <a href={`./statistic_id266572_us-smartphone-market-share-2012-2018-by-month.xlsx`}>
  Download dataset 🗳{" "}
    </a>
    <h2 {...{
      "id": "my-solution-"
    }}>{`My solution 👇`}</h2>
    <iframe {...{
      "src": "https://codesandbox.io/embed/0xj8q4k2pp",
      "style": {
        "width": "100%",
        "height": "500px",
        "border": "0",
        "borderRadius": "4px",
        "overflow": "hidden"
      },
      "allow": "accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking",
      "sandbox": "allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"
    }}></iframe>
    <h2 {...{
      "id": "how-it-works-️"
    }}>{`How it works ⚙️`}</h2>
    <p>{`We've built stackcharts before, on the `}<a parentName="p" {...{
        "href": "https://reactviz.holiday/christmas-gifts/"
      }}>{`What do Americans want for Christmas`}</a>{` day. That means we can focus on teh responsive part today.`}</p>
    <p>{`Although I still had to build the full stack chart from scratch and my jetlagged brain struggled. Sorry viewers. You might want to skip the first several minutes of the stream 😅`}</p>
    <h2 {...{
      "id": "how-to-make-a-responsive-chart-with-react-and-d3"
    }}>{`How to make a responsive chart with React and D3`}</h2>
    <p>{`There's two parts to making responsive charts and data visualizations:`}</p>
    <ol>
      <li parentName="ol">{`Build your chart so it conforms to a width and height`}</li>
      <li parentName="ol">{`Use CSS to resize your SVG based on viewport size`}</li>
      <li parentName="ol">{`React to window size changes`}</li>
      <li parentName="ol">{`Read SVG size`}</li>
      <li parentName="ol">{`Pass it into your chart`}</li>
    </ol>
    <p>{`We'll go from the outside-in.`}</p>
    <h3 {...{
      "id": "dynamically-sized-svg"
    }}>{`Dynamically sized SVG`}</h3>
    <p>{`There's a few ways you can render your SVG so it resizes based on available space. Flexbox, css grid, old school CSS tricks.`}</p>
    <p>{`The easist is a `}<inlineCode parentName="p">{`100%`}</inlineCode>{` width.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`<svg width="100%" height="400" ref={this.svgRef}>
  {data && (
    <ResponsiveStackChart
      data={data}
      keys={["android", "ios", "blackberry", "microsoft"]}
      width={width}
      height={height}
    />
  )}
</svg>
`}</code></pre>
    <p>{`Our SVG always occupies the full width of its parent div - the whole page in our case. It contains a `}<inlineCode parentName="p">{`<ResponsiveStackChart>`}</inlineCode>{` that accepts width, height, and data.`}</p>
    <p>{`Those four come from state.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const { data, width, height } = this.state
`}</code></pre>
    <p>{`You could track different widths for different charts, do some layouting, things like that. We don't need those complications because this is a small example.`}</p>
    <h3 {...{
      "id": "listen-to-window-size-changes"
    }}>{`Listen to window size changes`}</h3>
    <p>{`Now that we have a dynamic SVG, we have to read its size every time the window size changes. That happens when users resize their browser (never), or when they turn their phone (sometimes).`}</p>
    <p>{`In reality this part almost never happens. People rarely resize their browsers and only turn their phones if you give them a reason to. But it's a nice touch when we're talking about responsive :)`}</p>
    <p>{`We add a listener to the `}<inlineCode parentName="p">{`resize`}</inlineCode>{` window event in `}<inlineCode parentName="p">{`componentDidMount`}</inlineCode>{` and remove it in `}<inlineCode parentName="p">{`componentWillUnmount`}</inlineCode>{`. Both in the main App componenet.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`componentDidMount() {
    // data loading

    this.measureSVG();
    window.addEventListener("resize", this.measureSVG);
}

componentWillUnmount() {
    window.removeEventListener("resize", this.measureSVG);
}
`}</code></pre>
    <p><inlineCode parentName="p">{`measureSVG`}</inlineCode>{` is where the next bit happens.`}</p>
    <h3 {...{
      "id": "measure-svg-element-size"
    }}>{`Measure SVG element size`}</h3>
    <p>{`A useful DOM method engineers often forget exists is `}<inlineCode parentName="p">{`getBoundingClientRect`}</inlineCode>{`. Tells you the exact size of a DOM node. Great for stuff like this 👌`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`measureSVG = () => {
  const { width, height } = this.svgRef.current.getBoundingClientRect()

  this.setState({
    width,
    height,
  })
}
`}</code></pre>
    <p>{`Take the bounding client rect of our SVG element, read out its width and height, save it to state. This triggers a re-render of our app, passes new sizing props into the chart, and the chart resizes itself.`}</p>
    <p><img parentName="p" {...{
        "src": "https://media.giphy.com/media/12NUbkX6p4xOO4/giphy.gif",
        "alt": null
      }}></img></p>
    <h2 {...{
      "id": "a-chart-that-listens-to-its-width-and-height"
    }}>{`A chart that listens to its width and height`}</h2>
    <p>{`Now that we've got dynamic always accurate width and height, we have to listen to them.`}</p>
    <p>{`Best way to do that is with D3 scales that you keep up to date. We use the dynamic full integration approach from my `}<a parentName="p" {...{
        "href": "https://reactfordataviz.com"
      }}>{`React for Data Visualization`}</a>{` course.`}</p>
    <p>{`That means:`}</p>
    <ol>
      <li parentName="ol">{`Scales go into state`}</li>
      <li parentName="ol">{`Scales update their domain and range in `}<inlineCode parentName="li">{`getDerivedStateFromProps`}</inlineCode></li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`class ResponsiveStackChart extends React.Component {
  state = {
    xScale: d3
      .scaleBand()
      .domain(this.props.data.map(d => d.date))
      .range([0, 600]),
    yScale: d3.scaleLinear().range([0, 600])
  };
  stack = d3.stack().keys(this.props.keys);
  color = chroma.brewer.Paired;

  static getDerivedStateFromProps(props, state) {
    let { xScale, yScale } = state;

    xScale.domain(props.data.map(d => d.date)).range([0, props.width]);
    yScale.range([0, props.height - 50]);

    return {
      ...state,
      xScale,
      yScale
    };
  }
`}</code></pre>
    <p>{`We define default state for our `}<inlineCode parentName="p">{`xScale`}</inlineCode>{` and `}<inlineCode parentName="p">{`yScale`}</inlineCode>{`. Both assume the chart is going to be 600x600 pixels. xScale has a domain with every identifier in our dataset, the month/year, and yScale will get its domain in the render function. I'll explain why.`}</p>
    <p><inlineCode parentName="p">{`getDerivedStateFromProps`}</inlineCode>{` runs every time our component updates for any reason. A good place to update our scales so they fit any new into from props.`}</p>
    <p>{`We redefine their ranges to match the `}<inlineCode parentName="p">{`width`}</inlineCode>{` and `}<inlineCode parentName="p">{`height`}</inlineCode>{` props. If we are careful to always rely on scales to position and size elements on our chart, the chart will automatically resize.`}</p>
    <h3 {...{
      "id": "the-stack-layout"
    }}>{`The stack layout`}</h3>
    <p>{`To avoid calculating the stack layout multiple times, we do it in the render method. Need its data for rendering and for the `}<inlineCode parentName="p">{`yScale`}</inlineCode>{` domain.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`render() {
    const { data, height } = this.props,
      { yScale, xScale } = this.state;
    const stack = this.stack(data);

    yScale.domain([0, d3.max(stack[stack.length - 1].map(d => d[1]))]);
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`stack`}</inlineCode>{` generator returns an array of arrays. At the top level we have an array for every `}<inlineCode parentName="p">{`key`}</inlineCode>{` in our dataset. Inside is an array of tuples for each datapoint. The touples hold a `}<inlineCode parentName="p">{`min`}</inlineCode>{` and `}<inlineCode parentName="p">{`max`}</inlineCode>{` value that tells us where a datapoint starts and ends.`}</p>
    <p>{`We use `}<inlineCode parentName="p">{`d3.max`}</inlineCode>{` to find the highest value in the stack data and feed it into yScale's domain so it can proportionally size everything when we render.`}</p>
    <p>{`👌`}</p>
    <h2 {...{
      "id": "an-axis-with-dynamic-number-of-tricks"
    }}>{`An axis with dynamic number of tricks`}</h2>
    <p>{`The last step is making our axis look good at every size. We have to make sure ticks don't overlap and their number adapts to available space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const BottomAxis = d3blackbox((anchor, props) => {
  const scale = props.scale,
    tickWidth = 60,
    width = scale.range()[1],
    tickN = Math.floor(width / tickWidth),
    keepEveryNth = Math.floor(scale.domain().length / tickN)

  scale.domain(scale.domain().filter((_, i) => i % keepEveryNth === 0))

  const timeFormat = d3.timeFormat("%b %Y")
  const axis = d3.axisBottom().scale(props.scale).tickFormat(timeFormat)
  d3.select(anchor.current).call(axis)
})
`}</code></pre>
    <p>{`This is quite mathsy. The idea works like this:`}</p>
    <ol>
      <li parentName="ol">{`Decide how much room you want for each tick - `}<inlineCode parentName="li">{`tickWidth`}</inlineCode></li>
      <li parentName="ol">{`Read the width from scale.range - `}<inlineCode parentName="li">{`width`}</inlineCode></li>
      <li parentName="ol">{`Use division to decide how many ticks fit - `}<inlineCode parentName="li">{`tickN`}</inlineCode></li>
      <li parentName="ol">{`Some more division to decide every Nth tick you can keep - `}<inlineCode parentName="li">{`keepEveryNth`}</inlineCode></li>
    </ol>
    <p>{`Then we filter the scale's domain and keep only every `}<inlineCode parentName="p">{`keepEveryNth`}</inlineCode>{` element.`}</p>
    <p>{`Only reason we need this is because we're using a band scale, which is an ordinal scale. Means D3 can't easily interpolate datapoints and figure these things out on its own.`}</p>
    <p>{`The result is a perfectly responsive chart 👇`}</p>
    <div><div parentName="div" {...{
        "className": "static-tweet-embed"
      }}>{`
        `}<a parentName="div" {...{
          "className": "author",
          "href": "https://t.co/VuU1lFnIe7"
        }}><img parentName="a" {...{
            "src": "https://pbs.twimg.com/profile_images/1423736293385662466/AnF0Fsi6_normal.jpg",
            "loading": "lazy",
            "alt": "Swizec Teller writing a secret book avatar"
          }}></img><b parentName="a">{`Swizec Teller writing a secret book`}</b>{`@Swizec`}</a>{`
        `}<blockquote parentName="div">{`A responsive `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/react"
          }}>{`#react`}</a>{` and `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/d3"
          }}>{`#d3`}</a>{` stackchart. `}<a parentName="blockquote" {...{
            "href": "https://twitter.com/hashtag/ReactVizHoliday"
          }}>{`#ReactVizHoliday`}</a>{` 10`}<br parentName="blockquote"></br><br parentName="blockquote"></br>{`👉  `}</blockquote>{`
        `}<div parentName="div" {...{
          "className": "media"
        }}><img parentName="div" {...{
            "src": "https://pbs.twimg.com/ext_tw_video_thumb/1074202876506005506/pu/img/NVMV1x9WpoAWtRvC.jpg",
            "width": "100%",
            "loading": "lazy",
            "alt": "Tweet media"
          }}></img></div>{`
        `}<div parentName="div" {...{
          "className": "time"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1074202928221700097"
          }}>{`7:21:50 AM – 12/16/2018`}</a></div>{`
        `}<div parentName="div" {...{
          "className": "stats"
        }}><a parentName="div" {...{
            "href": "https://twitter.com/intent/like?tweet_id=1074202928221700097",
            "className": "like"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr",
              "style": {}
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M12 21.638h-.014C9.403 21.59 1.95 14.856 1.95 8.478c0-3.064 2.525-5.754 5.403-5.754 2.29 0 3.83 1.58 4.646 2.73.814-1.148 2.354-2.73 4.645-2.73 2.88 0 5.404 2.69 5.404 5.755 0 6.376-7.454 13.11-10.037 13.157H12zM7.354 4.225c-2.08 0-3.903 1.988-3.903 4.255 0 5.74 7.034 11.596 8.55 11.658 1.518-.062 8.55-5.917 8.55-11.658 0-2.267-1.823-4.255-3.903-4.255-2.528 0-3.94 2.936-3.952 2.965-.23.562-1.156.562-1.387 0-.014-.03-1.425-2.965-3.954-2.965z"
                }}></path></g></svg>{`2`}</a>{` `}<a parentName="div" {...{
            "href": "https://twitter.com/Swizec/status/1074202928221700097",
            "className": "reply"
          }}><svg parentName="a" {...{
              "viewBox": "0 0 24 24",
              "className": "r-m0bqgq r-4qtqp9 r-yyyyoo r-1xvli5t r-dnmrzs r-bnwqim r-1plcrui r-lrvibr"
            }}><g parentName="svg"><path parentName="g" {...{
                  "d": "M14.046 2.242l-4.148-.01h-.002c-4.374 0-7.8 3.427-7.8 7.802 0 4.098 3.186 7.206 7.465 7.37v3.828c0 .108.044.286.12.403.142.225.384.347.632.347.138 0 .277-.038.402-.118.264-.168 6.473-4.14 8.088-5.506 1.902-1.61 3.04-3.97 3.043-6.312v-.017c-.006-4.367-3.43-7.787-7.8-7.788zm3.787 12.972c-1.134.96-4.862 3.405-6.772 4.643V16.67c0-.414-.335-.75-.75-.75h-.396c-3.66 0-6.318-2.476-6.318-5.886 0-3.534 2.768-6.302 6.3-6.302l4.147.01h.002c3.532 0 6.3 2.766 6.302 6.296-.003 1.91-.942 3.844-2.514 5.176z"
                }}></path></g></svg>{`0`}</a></div>{`
    `}</div></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      